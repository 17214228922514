@import '/apps/wt/webtools/sparta_build_server/tmp/9C7439D6/artifacts/components/utilities/global/sparta-style-utility/4.9.5/scss/_sparta-project.scss';
$moduleNameSpace: dynamic-name-space;


$smallish-up: "(min-width: 40.063em)";

[data-sparta-container] .#{$moduleNameSpace} {
  .nav-search {
    transform: translateY(-27px);

    .search-input-container {
      width: auto; 
    }

    .search-form {
      width: 100%; 
    }

    .type-ahead {
      width: auto; 
    }
  }


  .search {
    position: relative;
    padding-top: 35px;
    background-color: $light-gray-secondary;
    border-bottom: 1px solid $sb-warm-gray-light;

    @media #{$large-up} {
      display: none;
    }

    @media #{$smallish-up} {
      border-top: 1px solid $sb-warm-gray-light;
    }

    .small-search {
      margin: 50px 30px 0;

      .medium-only & {
        max-width: 83.333%;
      }

      .nav-search {
        height: auto;
        left: 30px;
        position: absolute;
        right: 30px;
        top: 50px;
        visibility: visible;
        width: auto;

        .medium-only & {
          left: 10%;
          right: 10%;

          .type-ahead-item {
            margin: 4px;

            a {
              padding: 10px;
            }
          }
        }

        &.show-search-results .search-container {
          left: 0;
          margin-left: 0;
          margin-top: -1px;
          position: relative;
          top: 0;
          width: auto;

          &::after {
            display: none;
          }

        }

        .search-form {
          background-color: $white;
          border-radius: 0;
          position: static;

          .medium-only & {
            padding: 0 0 0 10px;
          }
        }

        .search-input-container {
          background-color: transparent;
          border: 0;
          max-height: none;
          padding: 0;

          .medium-only & {
            padding: 0 30px 5px 5px;
            width: auto;
          }

          .search-query {
            height: 40px !important;
            line-height: 22px !important;

            &::placeholder {
              color: $bank-dark-gray;
              font-style: normal;
            }

            .medium-only & {
              font-size: 18px !important;
            }
          }
          .icon-search {
            width: 35px;
            .medium-only & {
              width: 40px;
            }
          }
          .icon-search,
          .icon-search-large {
            background: $cool-gray;
          }
        }

        .icon.icon-search {
          svg {
            left: 6px;
            top: 11px;
            .medium-only & {
              left: 11px;
              top: 13px;
              transform: scale(1.25);
            }

            .search-icon-svg {
              stroke: $white;
            }
          }
        }

        .type-ahead {
          margin: 0;
          padding: 0 0 5px;
          width: auto;
        }

        .nav-search-suggestions {
          padding-left: 0;
        }

        .pagination .infinite-more {
          position: absolute;
        }
      }
    }
  }









  .menu-button-wrap {
    z-index: 111; 
  }
}
